<template>
  <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                        <v-card-title>
                            <v-btn
                            to="/work-orders"
                            color="#ffffff"
                            light
                            elevation="0"
                        >
                        <v-icon left large>mdi-chevron-left</v-icon>
                        </v-btn>
                        <h2>Work Order Schedule</h2>
                        </v-card-title>
                        <!--Calendar controls-->
                </v-col>
            </v-row>
            <v-row>
                <v-col :lg="changeColWidth">
                        <v-card>
                        <!--Loading bar-->
                
                        <v-toolbar
                            flat
                            >
                            <v-btn outlined class="mr-4" color="grey darken-2"
                                @click="setToday"
                            >
                                Today
                            </v-btn>
                            <v-btn fab text small color="grey darken-2"
                                @click="prev"
                            >
                                <v-icon small>
                                mdi-chevron-left
                                </v-icon>
                            </v-btn>
                            <v-btn fab text small color="grey darken-2"
                                @click="next"
                            >
                                <v-icon small>
                                mdi-chevron-right
                                </v-icon>
                            </v-btn>
                            <v-toolbar-title v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-menu
                                bottom
                                right
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    outlined
                                    color="grey darken-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>
                                    mdi-menu-down
                                    </v-icon>
                                </v-btn>
                                </template>
                                <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Day</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Week</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Month</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = '4day'">
                                    <v-list-item-title>4 days</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-toolbar>
                        <!--End of calendar controls-->
                        <v-sheet height="600">
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            :events="work_orders"
                            :event-color="getEventColor"
                            first-interval="8"
                            interval-count="24"
                            :type="type"
                            event-text-color="#1a1b1f"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                        ></v-calendar>
                    </v-sheet>
                    </v-card>
                </v-col>
                <!--Work order preview content-->
                <v-scroll-y-transition mode="out-in">
                    <v-col lg="4" v-if="selectedEvent.work_order_number">
                        <v-sheet>
                            <v-card
                                class="py-5"
                            >
                                <v-scroll-y-transition mode="out-in">
                                    <div
                                    :key="selectedEvent.work_order_number"
                                    class="mx-5"
                                    >
                                    <v-card-title>
                                        <h3>{{selectedEvent.work_order_number}}</h3>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-progress-linear
                                                :value="selectedEvent.work_order_progress"
                                                height="15"
                                                rounded
                                                :color="progressBarColor"
                                            ></v-progress-linear>
                                    </v-card-text>
                                    <v-card-text>
                                        <h4>Details</h4>
                                        <br>
                                        <!--Work Order Type-->
                                        <v-chip-group>
                                            <v-chip color="#3d2cdd" dark outlined label>
                                            <v-icon left small>{{saleOrStockIcon(selectedEvent.work_order_type)}}</v-icon>
                                                {{selectedEvent.work_order_type}}
                                            <span class="ml-1">
                                                {{selectedEvent.sales_order_number}}
                                            </span>
                                            </v-chip>
                                            <!--Production status-->
                                            <v-chip
                                                class="status-chip"
                                                label
                                                :color="getColorWorkOrders(selectedEvent.work_order_production_status)"
                                                :dark="getDarkOrLight(selectedEvent.work_order_production_status)"
                                                >
                                                <v-avatar
                                                left
                                                >
                                                <v-icon small>{{getWorkOrderIcon(selectedEvent.work_order_production_status)}}</v-icon>
                                                </v-avatar>
                                                {{ selectedEvent.work_order_production_status}}
                                            </v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                    <v-card-text>
                                        <h4>Customer:</h4>
                                        <br>
                                        <p>{{selectedEvent.customer_name}}</p>
                                    </v-card-text>
                                    
                                    <!--Deadline-->
                                    <v-card-text>
                                        <h4>Deadline</h4>
                                        <br>
                                        <p>{{selectedEvent.work_order_deadline}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <h4>Line Items</h4>
                                        <br>
                                        <v-list v-for="item in selectedEvent.line_items" :key="item.product_name">
                                            <p>
                                                {{item.item_qty}} x {{item.product_name}}
                                            </p>
                                        </v-list>
                                        
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            elevation="2"
                                            color="#e7e7f7"
                                            light
                                            :to="{ name: 'edit-work-order', params: { work_order_id: selectedEvent.work_order_id }}"
                                            >
                                            <v-icon left>mdi-pencil</v-icon>
                                            Edit Work Order
                                        </v-btn>
                                    </v-card-actions>
                                    </div>
                                </v-scroll-y-transition>
                            </v-card>
                        </v-sheet>
                    </v-col>
                </v-scroll-y-transition>
                <!--end of work order preview content-->
            </v-row>
  </v-container>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js

export default {
    name: 'WorkOrderSchedule',

    data: () => ({
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      work_orders: [],
    }),
    computed:{
        progressBarColor(){
            return this.work_order_progress < 100 ? '#f9c82b' : "#33cc99"
        }
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    //Get work orders from database
    created(){
        db.collection('work_orders').orderBy('work_order_number').get().then(querySnapshot => {
          querySnapshot.forEach(doc => {
            console.log(doc.id)
            const data = {
              'id': doc.id,
              'work_order_id': doc.data().work_order_id,
              'work_order_number': doc.data().work_order_number,
              'work_order_type': doc.data().work_order_type,
              'work_order_date_created': doc.data().work_order_date_created,
              'work_order_production_status': doc.data().work_order_production_status,
              'work_order_deadline': doc.data().work_order_deadline,
              'work_order_progress': doc.data().work_order_progress,
              'sales_order_number': doc.data().sales_order_number,
              'sales_order_updated': doc.data().sales_order_updated,
              'sales_order_cancelled': doc.data().sales_order_cancelled,
              'start': doc.data().work_order_deadline,
              'name': doc.data().work_order_number,
              'line_items': doc.data().line_items,
              'customer_name': doc.data().customer_name
              
            }
            //Pushes the customers objects to the empty customers array above
            this.work_orders.push(data)
          })
        })
      },
    methods: {
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        if(event.work_order_production_status === 'Work Order Created'){
            return '#c5d0ed'
        }else if(event.work_order_production_status === 'In Progress'){
            return '#f9d493'
        }else if(event.work_order_production_status === 'Completed'){
            return '#88e2b7'
        }else if(event.work_order_production_status === 'On Hold'){
            return '#ef9ebd'
        }else if(event.work_order_production_status === 'Cancelled'){
            return '#ce2458'
        }
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
        nativeEvent.stopPropagation()
      },

      //Determine work order status icon
        saleOrStockIcon(work_order_type) {
            if(work_order_type==="From Sale"){
              return 'mdi-tag-plus'
          }else if(work_order_type==='For Stock'){
              return 'mdi-warehouse'
          }
        },
        //Conditional formatting for work order status
      getColorWorkOrders (work_order_production_status) {
        if (work_order_production_status == "Work Order Created") return '#c5d0ed'
        else if (work_order_production_status == "In Progress") return '#f9d493'
        else if (work_order_production_status == "Completed") return '#88e2b7'
        else if (work_order_production_status == "On Hold") return '#ef9ebd'
        else if (work_order_production_status == "Cancelled") return '#ce2458'
        else return 'white'
      },
      getWorkOrderIcon(work_order_production_status) {
        if (work_order_production_status == "Work Order Created") return 'mdi-tag-plus'
        else if (work_order_production_status == "In Progress") return 'mdi-camera-timer'
        else if (work_order_production_status == "Completed") return 'mdi-check'
        else if (work_order_production_status == "On Hold") return 'mdi-pause'
        else if (work_order_production_status == "Cancelled") return 'mdi-cancel'
        else return 'white'
      },
      getDarkOrLight(work_order_production_status){
        if (work_order_production_status != "Cancelled") return false
        else return true
      },
    //  Changes width of Calendar column to make space for Work Order
    //  preview
      changeColWidth(){
          if (this.selectedEvent.work_order_number == true){
              return 8
          }else{
              return 12
          }
      }
      
    },
  }
</script>