<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('work_orders_write')">
        <v-col lg="8" offset-lg="2">
            <v-card-title>
                <v-btn
                    to="/work-orders"
                    color="#ffffff"
                    light
                    elevation="0"
                >
                <v-icon left large>mdi-chevron-left</v-icon>
                </v-btn>
                <h3>Create New Work Order</h3>
                <!--Show secondary action button-->
                <v-spacer></v-spacer>
                    <v-btn
                        elevation="2"
                        color="#e7e7f7"
                        light
                        class="ml-10"
                        @click="generateWorkOrder"
                    >
                        <v-icon left>mdi-printer</v-icon>
                        Print Work Order
                    </v-btn>
                </v-card-title>
            <v-card>
                <!--New Work Order form -->
                <v-form @submit.prevent="saveWorkOrder" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <h4>Work Order Details</h4>
                            </v-col>
                        </v-row>
                        <!--Progress Bar-->
                            <v-row>
                                <v-col cols="12">
                                    <v-progress-linear
                                        v-model="progressBar"
                                        height="15"
                                        rounded
                                        color="#f9c82b"
                                    >

                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                            <!--End of progress bar-->
                        <v-row>
                            <!--Work Order number-->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    :value="work_order_number"
                                    label="Work Order #"
                                    readonly
                                    single-line
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Deadline Date Picker-->
                            <v-col cols="12" sm="6" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="true"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="work_order_deadline"
                                        label="Deadline"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        clearable
                                        color="#3d2cdd"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="work_order_deadline"
                                        no-title
                                        scrollable
                                        color="#3d2cdd"
                                        show-adjacent-months
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="#ce2458"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="#33cc99"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!--Work Order status-->
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    v-model="work_order_production_status"
                                    required
                                    :items="work_order_status_items"
                                    label="Production Status"
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-col>
                        </v-row>

                        <!--Line Items-->
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Line Items</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <draggable :list="line_items" group="line_items" :options="{handle:'.dragg-me'}">
                                    <transition-group>
                                <v-form v-for="(item, index) in line_items" :key="index">
                                    <v-container @mouseover="hover = true"
                                                @mouseleave="hover = false">
                                        <v-row>
                                            <!--Drag handle-->
                                            <v-col sm="1" md="1">
                                                <v-btn
                                                    class="dragg-me"
                                                    v-if="hover"
                                                    icon
                                                    large
                                                    color="#141442"
                                                >
                                                    <v-icon
                                                        
                                                    >mdi-drag</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col sm="3" md="3">
                                            <v-autocomplete
                                                    v-model="item.product_name"
                                                    :items="products"
                                                    item-text="product_name"
                                                    placeholder="Choose a product..."
                                                    :rules="[RequireFormInput('product')]"
                                                    cache-items
                                                    trim
                                                    required
                                                    outlined
                                                    dense
                                                    color="#3d2cdd"
                                                >
                                                <!--Product Selection-->
                                                <template v-slot:selection="data" trim>
                                                    {{ data.item.product_name }}
                                                </template>
                                                <!--Dropdown items-->
                                                <template v-slot:item="data">
                                                    <v-list-item-avatar size="56" tile>
                                                        <img :src="data.item.product_img">
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.product_name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.product_code"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                            </template>
                                                    <!--A button that lets users add new products if not in select list
                                                    Reroute to Add Product interface-->
                                                    <template>
                                                        <v-list-item slot="append-item">
                                                            <v-btn
                                                            text
                                                            color="#3d2cdd"
                                                            to="/new-product"
                                                            ><v-icon>mdi-plus</v-icon> Add New Product</v-btn>
                                                        </v-list-item>
                                                    </template>
                                                    </v-autocomplete>
                                            </v-col>
                                                    <!--end of product dropdown-->
                                            <!--Quantity Required-->
                                            <v-col sm="2" md="2">
                                                <v-text-field
                                                    v-model.number="item.item_qty"
                                                    min="0"
                                                    step="1"
                                                    label="QTY Required"
                                                    placeholder="QTY Required"
                                                    type="number"
                                                    outlined
                                                    dense
                                                    color="#3d2cdd"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <!--Quantity completed-->
                                            <v-col sm="2" md="2">
                                                <v-text-field
                                                    v-model.number="item.qty_completed"
                                                    min="0"
                                                    step="1"
                                                    label="QTY Completed"
                                                    placeholder="QTY Completed"
                                                    type="number"
                                                    outlined
                                                    dense
                                                    color="#3d2cdd"
                                                >
                                                </v-text-field>
                                            </v-col>
                                             <!--Employee Assigned-->
                                            <v-col sm="3" md="3">
                                                <v-autocomplete
                                                    v-model="item.employee_assigned"
                                                    :items="employees"
                                                    item-text="employee_fullName"
                                                    color="#3d2cdd"
                                                    outlined
                                                    dense
                                                    label="Employee Assigned"
                                                >
                                                <template v-slot:selection="data" trim>
                                                    {{data.item.employee_fullName}}
                                                </template>
                                                <!--Dropdown items-->
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title> {{data.item.employee_fullName}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.employee_jobDescription}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                                </v-autocomplete>
                                            </v-col>
                                            <!--Delete Button-->
                                            <v-col sm="1" md="1">
                                                <v-btn
                                                    @click="removeItem(index)"
                                                    icon
                                                    large
                                                    color="#ce2458"
                                                    :disabled="deleteDisable"
                                                >
                                                    <v-icon
                                                        color="#ce2458"
                                                        small
                                                    >mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                                </transition-group>
                                </draggable>
                                <!--Add more items to order-->
                                <v-row>
                                    <v-col col="12" offset-md="1" offset-sm="1">
                                        <v-btn
                                            @click="addItems"
                                            color="#33cc99"
                                            dark
                                            large
                                        >
                                        <v-icon left>mdi-plus</v-icon>
                                        Add Item
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <!--Special instructions-->
                        <v-row class="mt-5">
                            <v-col cols="12" sm="12" md="12">
                                <h4>Special Instructions (Notes)</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                    v-model="work_order_special_instructions"
                                    clearable
                                    counter
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/work-orders'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveWorkOrder"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import draggable from 'vuedraggable'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_InitWorkOrder from '../mixins/mixin_InitWorkOrder'
import mixin_PDFworkOrder from '../mixins/mixin_PDFworkOrder'
import mixin_CompanyProfile from '../../../globalActions/mixin_CompanyProfile'
import mixin_progressBar from '../mixins/mixin_progressBar'
//Components
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'NewWorkOrder_ForStock',
    components: {draggable, NoPermissionsCard},
    mixins: [
        mixin_UserRoles, mixin_InitWorkOrder, mixin_PDFworkOrder,
        mixin_CompanyProfile, mixin_progressBar,
    ],
    data(){
        return{
            deleteDisable: true,
            //Products array is populated from database
            products: [],
            hover: false,
            work_order_type: 'For Stock',
            loading: false,
            work_order_created_by_id: null,
            work_order_created_by_name: null,
        }
    },
    created(){
      //Get Work Order Counter From DB
      db.collection('work_order_number_counter')
      .doc('ruQc3stD9jaDZpBEJ5wG').get().then(snapshot => {
          // Gets corder and quote counter object from datatbase
          const count = snapshot.data().work_order_count
          this.work_order_number = "WO-".concat(count.toString())      
      }),
      //Get employees from database
      db.collection('employees').where('employee_status', '==', 'Employed').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the customers name to the empty customers array in 'data' above
              this.employees.push(data)
            })
        }),
        // Gets products from firebase datatbase
          db.collection('products').where('product_status', '==', 'Available').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the products name to the empty customers array in 'data' above
              this.products.push(data)
    
            })
          }),
        //Captures user that created the sales order
        this.work_order_created_by_id = firebase.auth().currentUser.uid
        db.collection('users').where('uid', '==', this.work_order_created_by_id).get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
            this.work_order_created_by_name = doc.data().displayName
            })
        })
    },
    methods: {
        //Adds a new empty line item to the order
        addItems() {
            this.line_items.push({
                product_name: '',
                item_qty: 0,
                qty_completed: 0,
                employee_assigned: 0
            })
            
            this.deleteDisable = false
        },
        //Deletes a specific line item from the order
        removeItem: function(index) {
            this.line_items.splice(index,1)
            if(this.line_items.length === 1){
                this.deleteDisable = true
            }
        },
        saveWorkOrder () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading = true
                var newDocRef = db.collection('work_orders').doc();
                    newDocRef.set({
                    //Populated by the v-model values in the form
                    //NB: Sets the order_id == to the document id in database
                    work_order_id: newDocRef.id,
                    work_order_number: this.work_order_number,
                    work_order_type: this.work_order_type,
                    work_order_deadline: this.work_order_deadline,
                    work_order_production_status: this.work_order_production_status,
                    work_order_special_instructions: this.work_order_special_instructions,
                    line_items: this.line_items,
                    work_order_date_created: new Date().toISOString().slice(0, 10),
                    work_order_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    work_order_created_by_id: this.work_order_created_by_id,
                    work_order_created_by_name: this.work_order_created_by_name,
                })
                .then(() => {
                    this.loading = false
                    const db = firebase.firestore();
                        //Work Order number counter
                        //A counter in a doc in firebase is incremented when a new work order is generated
                        //The latest count is then used as the next work order number
                        const increment = firebase.firestore.FieldValue.increment(1);
                        const workOrderNumberCount = db.collection('work_order_number_counter').doc('ruQc3stD9jaDZpBEJ5wG');
                        //Increments work order counter
                        workOrderNumberCount.update({ work_order_count: increment });
                        showSnackbar("New Work Order created successfully!");
                        //Reroute to work orders list after database is updated
                        this.$router.push('/work-orders')
                })
                //Update the work order status on the sales order
                //Send Work order number and ID to sales order
            }
        }
    }
}
</script>