<template>
    <v-container  fluid>
    <v-row dense>
        <v-col lg="8" offset-lg="2">
            <v-card-title>
                    <v-btn
                        to="/work-orders"
                        color="#ffffff"
                        light
                        elevation="0"
                    >
                    <v-icon left large>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h3>Edit Work Order</h3>
                    <!--Show secondary action button-->
                    <v-spacer></v-spacer>
                            <v-btn
                                elevation="2"
                                color="#e7e7f7"
                                light
                                class="ml-10"
                                @click="generateWorkOrder"
                            >
                                <v-icon left>mdi-printer</v-icon>
                                Print Work Order
                            </v-btn>
                </v-card-title>
            <v-card>
                <!--Snackbar Notification-->
                <!--snackbar messages are called on firebase actions-->
                <v-snackbar
                    v-model="alertSnackbar"
                    :timeout="-1"
                    :color="alertSnackbarColor"
                    top
                >
                <v-icon left small>mdi-alert</v-icon>
                Sales Order {{this.sales_order_number}} has been {{cancelledOrUpdated}}
                    
                    <!--Update preview modal-->
                    <v-dialog
                              v-model="dialog"
                              max-width="480"
                            >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="updatedOnly()"
                                text
                                v-bind="attrs"
                                v-on="on"
                            >View Updates<v-icon right>mdi-eye</v-icon></v-btn>
                          </template>
                          <!--Modal body-->
                          <v-card :draggable="true">
                              <v-card-title>
                                  <h3>Updated Order ({{this.sales_order_number}})</h3>
                              </v-card-title>
                              <v-container>
                                <v-list v-for="item in updated_line_items" :key="item.product_name">
                                    <v-list-item>
                                        <v-list-item-content>
                                            {{item.item_qty}}x {{item.product_name}}
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-card-actions>
                                <v-btn
                                    @click="updateAllWorkOrderItems()"
                                    color="#33cc99"
                                    class="white-text"
                                >Update All Items To Work Order</v-btn>
                              </v-card-actions>
                              </v-container>
                              
                          </v-card>
                    </v-dialog>

                <template v-slot:action="{ attrs }">
                    <v-btn
                    icon
                    right
                    v-bind="attrs"
                    @click="alertSnackbar = false"
                    >
                    <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
                </v-snackbar>


                <!--Edit Work Order form -->
                <v-form @submit.prevent="updateWorkOrder" class="pa-3" ref="form" v-model="valid">
                    <v-container>
                        <!--Progress Bar-->
                        <v-row>
                            <v-col cols="12">
                                <v-progress-linear
                                    v-model="progressBar"
                                    height="15"
                                    rounded
                                    :color="progressBarColor"
                                >

                                </v-progress-linear>
                            </v-col>
                        </v-row>
                        <!--End of progress bar-->
                        <v-row>
                            <v-col cols="12">
                                <h4>Work Order Details</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Work Order number-->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                    :value="work_order_number"
                                    label="Work Order #"
                                    readonly
                                    single-line
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <!--Sales Order # is filled in automatically-->
                            <v-col cols="12" sm="12" md="6"
                                v-if="work_order_type != 'For Stock'"
                            >
                                <v-text-field
                                    v-model="sales_order_number"
                                    readonly
                                    cache-items
                                    label="Sales Order"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <!--Deadline Date Picker-->
                            <v-col cols="12" sm="6" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="true"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="work_order_deadline"
                                        label="Deadline"
                                        append-icon="mdi-calendar"
                                        :rules="[RequireFormInput('date')]"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        outlined
                                        clearable
                                        color="#3d2cdd"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="work_order_deadline"
                                        no-title
                                        scrollable
                                        color="#3d2cdd"
                                        show-adjacent-months
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="#ce2458"
                                        @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="#33cc99"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <!--Work Order status-->
                            <v-col cols="12" sm="12" md="6">
                                <v-select
                                    :v-model="jobStatusValue()"
                                    required
                                    :items="work_order_status_items"
                                    label="Production Status"
                                    color="#3d2cdd"
                                    outlined
                                    dense
                                >
                                </v-select>
                            </v-col>
                        </v-row>

                        <!--Line Items-->
                        <v-row>
                            <v-col sm="4" md="4">
                                <h4>Line Items</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-form v-for="item in line_items" :key="item.product_name">
                                    <v-container>
                                        <v-row>
                                            <!--Product Name-->
                                            <v-col sm="4" md="4">
                                                <v-text-field
                                                    v-model="item.product_name"
                                                    label="Item"
                                                    color="#3d2cdd"
                                                    readonly
                                                />
                                            </v-col>
                                            <!--Quantity Required-->
                                            <v-col sm="2" md="2">
                                                <v-text-field
                                                    v-model.number="item.item_qty"
                                                    label="Qty Required"
                                                    color="#3d2cdd"
                                                    min="0"
                                                    step="1"
                                                    type="number"
                                                    outlined
                                                    dense
                                                />
                                            </v-col>
                                            <!--Quantity completed-->
                                            <v-col sm="2" md="2">
                                                <v-text-field
                                                    v-model.number="item.qty_completed"
                                                    label="Qty Completed"
                                                    color="#3d2cdd"
                                                    type="number"
                                                    min="0"
                                                    :max="item.item_qty"
                                                    step="1"
                                                    outlined
                                                    dense
                                                />
                                            </v-col>
                                             <!--Employee Assigned-->
                                            <v-col sm="4" md="4">
                                                <v-select
                                                    v-model="item.employee_assigned"
                                                    :items="employees"
                                                    item-text="employee_fullName"
                                                    outlined
                                                    dense
                                                    label="Employee Assigned"
                                                    :loading="loading_employee"
                                                >
                                                <template v-slot:selection="data" trim>
                                                    {{data.item.employee_fullName}}
                                                </template>
                                                <!--Dropdown items-->
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title> {{data.item.employee_fullName}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.employee_jobDescription}}</v-list-item-subtitle>
                                                        
                                                    </v-list-item-content>
                                                </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-col>
                        </v-row>
                        <!--Special instructions-->
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <h4>Special Instructions (Notes)</h4>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea
                                                    v-model="work_order_special_instructions"
                                                    clearable
                                                    counter
                                                    color="#3d2cdd"
                                                    outlined
                                                    dense
                                                >

                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                    </v-container>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/work-orders'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete work order button-->
                    <v-btn
                        @click='deleteWorkOrder'
                        v-if="this.user_roles_list.flat().includes('work_orders_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateWorkOrder"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import store from '../store/store'
import {mapState} from 'vuex'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_InitWorkOrder from '../mixins/mixin_InitWorkOrder'
import mixin_CompanyProfile from '../../../globalActions/mixin_CompanyProfile'
import mixinPDFworkOrder from '../mixins/mixin_PDFworkOrder'
import mixin_progressBar from '../mixins/mixin_progressBar'

import {showSnackbar} from '../../../globalActions/index'

export default {
    name: 'EditWorkOrder',
    mixins: [
            mixin_UserRoles, mixin_InitWorkOrder, mixin_CompanyProfile,
            mixinPDFworkOrder, mixin_progressBar
    ],
    data(){
        return{
            //Sales orders populated from database
            sales_order_number: null,
            sales_order_id: null,
            sales_order_updated: false,
            sales_order_cancelled: false,
            updated_line_items: null,
            alertSnackbar: false,
            dialog: false,
            work_order_type: 'From Sale',
            loading_employee: false,
            loading_update: false,
            loading_delete: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('work_orders').where('work_order_id', '==', to.params.work_order_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.work_order_id = doc.data().work_order_id
                    vm.work_order_number = doc.data().work_order_number
                    vm.work_order_type = doc.data().work_order_type   
                    vm.sales_order_number = doc.data().sales_order_number 
                    vm.sales_order_cancelled = doc.data().sales_order_cancelled
                    vm.sales_order_updated = doc.data().sales_order_updated
                    vm.customer_name = doc.data().customer_name
                    vm.updated_line_items = doc.data().updated_line_items
                    vm.sales_order_id = doc.data().sales_order_id  
                    vm.work_order_date_created = doc.data().work_order_date_created
                    vm.work_order_deadline = doc.data().work_order_deadline   
                    vm.work_order_progress = doc.data().work_order_progress
                    vm.work_order_production_status = doc.data().work_order_production_status
                    vm.work_order_special_instructions = doc.data().work_order_special_instructions
                    vm.line_items = doc.data().line_items            
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    created(){
        //Get employees from database
        this.loading_employee = true
        db.collection('employees').where('employee_status', '==', 'Employed').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              const data = doc.data()
              //Pushes the customers name to the empty customers array in 'data' above
              this.employees.push(data)
              this.loading_employee = false
            })
        })
    },
    mounted(){
        //Triggers notification if sales order has been cancelled / deleted
        if(this.sales_order_cancelled == true || this.sales_order_updated == true){
            this.alertSnackbar = true
        }
    },
    store,
    computed: mapState ({
        //This is where data is retrieved from the Vuex state
        editWorkOrderFormHeader: state => state.editWorkOrderFormHeader,

        cancelledOrUpdated(){
            return this.sales_order_cancelled === true ? "cancelled or deleted" : "updated"
        },
        alertSnackbarColor(){
            return this.sales_order_cancelled === true ? "#ce2458" : "#FFB300"
        },
        //Changes the colour of the progress bar when completed
        progressBarColor(){
            return this.progressBar < 100 ? '#f9c82b' : "#33cc99"
        },        
    }),
    methods: {
        //Maps database data to template relative to the work order selected in this route
        fetchData () {
        db.collection('work_orders').where('work_order_id', '==', this.$route.params.work_order_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.work_order_id = doc.data().work_order_id
                this.work_order_number = doc.data().work_order_number
                this.work_order_type = doc.data().work_order_type   
                this.sales_order_number = doc.data().sales_order_number 
                this.sales_order_id = doc.data().sales_order_id  
                this.sales_order_cancelled = doc.data().sales_order_cancelled
                this.sales_order_updated = doc.data().sales_order_updated
                this.updated_line_items = doc.data().updated_line_items
                this.customer_name = doc.data().customer_name
                this.work_order_date_created = doc.data().work_order_date_created
                this.work_order_deadline = doc.data().work_order_deadline 
                this.work_order_progress = doc.data().work_order_progress  
                this.work_order_production_status = doc.data().work_order_production_status
                this.work_order_special_instructions = doc.data().work_order_special_instructions 
                this.line_items = doc.data().line_items
          })
        })
      },
      //UPDATED SALES ORDER RELATED CODE
      //show the "View Updates" button in the snackbar
      updatedOnly(){
          if(this.sales_order_updated === true && this.sales_order_cancelled === false){
              return true
          }
      },
      //Update the work order to contain all the items of the sales order
      updateAllWorkOrderItems(){
          this.line_items = this.updated_line_items
          this.alertSnackbar = false
          this.dialog = false
          this.sales_order_updated = false
      },
      jobStatusValue(){
          if(this.work_order_progress === 0){
              return this.work_order_production_status = 'Work Order Created'
          }else if(this.work_order_progress != 0 && this.work_order_progress < 100){
              return this.work_order_production_status = 'In Progress'
          }else if(this.work_order_progress === 100){
              return this.work_order_production_status = 'Completed'
          }else if(this.work_order_progress === 100 && this.sales_order_updated === true){
              return this.work_order_production_status = 'In Progress'
          }
      },
      //Updates form values in the database
        updateWorkOrder () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('work_orders').where('work_order_id', '==', this.$route.params.work_order_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            work_order_id: this.work_order_id,
                            work_order_number: this.work_order_number,
                            work_order_type: this.work_order_type,
                            work_order_date_created: this.work_order_date_created,
                            work_order_deadline: this.work_order_deadline,
                            work_order_production_status: this.work_order_production_status,
                            work_order_progress: this.work_order_progress,
                            work_order_special_instructions: this.work_order_special_instructions,
                            line_items: this.line_items
                        })
                    })
                })
                //Update that only triggers for Work orders from Sales Orders
                if(this.work_order_type === 'From Sale'){
                    db.collection('work_orders').where('work_order_id', '==', this.$route.params.work_order_id).get()
                    .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                    doc.ref.update({
                            sales_order_number: this.sales_order_number,
                            sales_order_cancelled: this.sales_order_cancelled,
                            sales_order_updated: this.sales_order_updated,
                            updated_line_items: this.updated_line_items,
                            sales_order_id: this.sales_order_id,
                            customer_name: this.customer_name,
                            })
                        })
                    })
                    db.collection('sales_orders').where('order_id', '==', this.sales_order_id).get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            doc.ref.update({
                                work_order_production_status: this.work_order_production_status,
                                work_order_deadline: this.work_order_deadline
                            })
                            //Reroute to sales order view page after database has been updated
                            .then(() => {
                                this.loading_update = false
                                showSnackbar("Work Order updated successfully");
                                //Reroute to work orders list after database is updated
                                this.$router.push('/work-orders')
                            })
                        })
                    }) 
                }else if(this.work_order_type === 'For Stock'){
                    this.loading_update = false
                    showSnackbar("Work Order updated successfully");
                    //Reroute to work orders list after database is updated
                    this.$router.push('/work-orders')
                }
            } 
        },
        //Removes work order from database
        deleteWorkOrder () {
            if(confirm('Are you sure you want to delete this Work Order?')){
                this.loading_delete = true
                db.collection('work_orders').where('work_order_id', '==', this.$route.params.work_order_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                    })
                })
                //Reset production status on work order
                //Remove work order ID from sales order
                    if(this.sales_order_cancelled === false){
                    db.collection('sales_orders').where('order_id', '==', this.sales_order_id).get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            doc.ref.update({
                                work_order_production_status: 'Not Started',
                                work_order_id: null,
                            })
                            //Reroute to sales order view page after database has been updated
                            .then(() => {
                                this.loading_delete = false
                                showSnackbar("Work Order deleted successfully");
                                //Reroute to work orders list after database is updated
                                this.$router.push('/work-orders')
                            })
                        })
                    })
                } else{
                    this.loading_delete = false
                    showSnackbar("Work Order deleted successfully");
                    this.$router.push('/work-orders')
                }
            }
        }
    }
}
</script>

<style scoped>
  .white-text{
    color: #fff;
  }
</style>