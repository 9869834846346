<template>
  <v-container fluid>
    <v-row dense>
      <v-col lg="8" offset-lg="2">
        <v-card-title>
          <v-btn to="/work-orders" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Work Order Summary</h3>
          <!--Generate PDF button-->
          <v-spacer></v-spacer>
          <v-btn
            elevation="2"
            color="#e7e7f7"
            light
            class="ml-10"
            @click="generateWorkOrder"
          >
            <v-icon left>mdi-printer</v-icon>
            Print Work Order
          </v-btn>
        </v-card-title>
        <!--Alert for Updates and Cancellations-->
        <v-alert
          v-if="sales_order_cancelled || sales_order_updated"
          :color="alertSnackbarColor"
          class="text-center"
          dark
        >
          <v-icon left small>mdi-alert</v-icon>
          Sales Order {{ sales_order_number }} has been {{ cancelledOrUpdated }}
        </v-alert>
        <v-sheet elevation="2" class="pa-5">
          <v-container>
            <!--Document title-->
            <v-row>
              <v-col>
                <h1>Work Order {{ work_order_number }}</h1>
              </v-col>
            </v-row>
            <!--Company Details-->
            <v-row>
              <v-col sm="3" md="3">
                <v-img :src="company_logo" max-width="150"></v-img>
              </v-col>
              <v-spacer></v-spacer>
              <v-col sm="3" md="3" class="text-right caption">
                <v-col>
                  {{ company_legal_name }}<br />
                  <span v-if="company_trading_as != null"
                    >T/A {{ company_trading_as }}</span
                  ><br />
                  Reg No. {{ company_reg_number }}<br />
                  VAT No. {{ company_VAT_number }}
                </v-col>
                <v-col>
                  {{ company_physical_address_line_1 }}<br />
                  {{ company_physical_address_line_2 }}<br />
                  {{ company_physical_address_city }}
                  {{ company_physical_address_zip }}<br />
                </v-col>
              </v-col>
            </v-row>
            <!--Divider-->
            <v-row class="my-3">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!--Work Order details-->
            <v-row>
              <v-col sm="3" md="3">
                <h4>Work Order No.</h4>
              </v-col>
              <v-col sm="3" md="3">
                <h4>Type</h4>
              </v-col>
              <v-col sm="3" md="3">
                <h4>Issued By</h4>
              </v-col>
              <v-col sm="3" md="3">
                <h4>Deadline</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="3" md="3">
                {{ work_order_number }}
              </v-col>
              <v-col sm="3" md="3">
                {{ work_order_type }}
              </v-col>
              <v-col sm="3" md="3">
                {{ work_order_created_by_name }}<br />
                {{ work_order_date_created }}
              </v-col>
              <v-col sm="3" md="3">
                {{ work_order_deadline }}
              </v-col>
            </v-row>
            <!--Divider-->
            <v-row class="my-3">
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <!--Sales Details-->
            <div v-if="work_order_type === 'From Sale'">
              <v-row>
                <v-col sm="3" md="3">
                  <h4>Sales Order No.</h4>
                </v-col>
                <v-col sm="3" md="3">
                  <h4>Customer</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="3" md="3">
                  <router-link
                    :to="{
                      name: 'view-order',
                      params: { order_id: sales_order_id },
                    }"
                  >
                    {{ sales_order_number }}
                  </router-link>
                </v-col>
                <v-col sm="3" md="3">
                  {{ customer_name }}
                </v-col>
              </v-row>
              <!--Divider-->
              <v-row class="my-3">
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </div>
            <!--Line items-->
            <v-row>
              <v-col>
                <h3>Line Items</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4">
                <h4>Item Name</h4>
              </v-col>
              <v-col sm="2" md="2" class="text-center">
                <h4>Qty Required</h4>
              </v-col>
              <v-col sm="3" md="3" class="text-center">
                <h4>Qty Completed</h4>
              </v-col>
              <v-col sm="3" md="3">
                <h4>Assigned To</h4>
              </v-col>
            </v-row>
            <v-row v-for="item in line_items" :key="item.product_name">
              <v-col sm="4" md="4">
                {{ item.product_name }}
              </v-col>
              <v-col sm="2" md="2" class="text-center">
                {{ item.item_qty }}
              </v-col>
              <v-col sm="3" md="3" class="text-center">
                {{ item.qty_completed }}
              </v-col>
              <v-col sm="3" md="3">
                {{ item.employee_assigned }}
              </v-col>
            </v-row>
            <!--Edit button-->
            <v-row>
              <v-col>
                <v-card-actions class="mt-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    text
                    color="#33cc99"
                    :to="{
                      name: 'edit-work-order',
                      params: { work_order_id: work_order_id },
                    }"
                  >
                    <v-icon left>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
import mixinCompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import mixinPDFworkOrder from "../mixins/mixin_PDFworkOrder";
export default {
  name: "ViewWorkOrder",
  mixins: [mixinCompanyProfile, mixinPDFworkOrder],
  data() {
    return {
      work_order_id: null,
      work_order_number: null,
      work_order_type: null,
      work_order_date_created: null,
      work_order_deadline: null,
      sales_order_id: null,
      sales_order_number: null,
      sales_order_updated: null,
      sales_order_cancelled: null,
      offset: null,
      line_items: null,
      work_order_created_by_name: null,
      attachments: null,
    };
  },
  computed: {
    cancelledOrUpdated() {
      return this.sales_order_cancelled === true
        ? "cancelled or deleted"
        : "updated";
    },
    alertSnackbarColor() {
      return this.sales_order_cancelled === true ? "#ce2458" : "#FFB300";
    },
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("work_orders")
      .where("work_order_id", "==", to.params.work_order_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.work_order_id = doc.data().work_order_id;
            vm.work_order_number = doc.data().work_order_number;
            vm.work_order_type = doc.data().work_order_type;
            vm.sales_order_number = doc.data().sales_order_number;
            vm.sales_order_cancelled = doc.data().sales_order_cancelled;
            vm.sales_order_updated = doc.data().sales_order_updated;
            vm.customer_name = doc.data().customer_name;
            vm.updated_line_items = doc.data().updated_line_items;
            vm.sales_order_id = doc.data().sales_order_id;
            vm.work_order_date_created = doc.data().work_order_date_created;
            vm.work_order_deadline = doc.data().work_order_deadline;
            vm.work_order_progress = doc.data().work_order_progress;
            vm.work_order_production_status =
              doc.data().work_order_production_status;
            vm.work_order_special_instructions =
              doc.data().work_order_special_instructions;
            vm.line_items = doc.data().line_items;
            vm.work_order_created_by_name =
              doc.data().work_order_created_by_name;
            vm.attachments = doc.data().attachments;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  mehods: {
    //Maps database data to template relative to the customer selected in this route
    fetchData() {
      db.collection("work_orders")
        .where("work_order_id", "==", this.$route.params.work_order_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.work_order_id = doc.data().work_order_id;
            this.work_order_number = doc.data().work_order_number;
            this.work_order_type = doc.data().work_order_type;
            this.sales_order_number = doc.data().sales_order_number;
            this.sales_order_id = doc.data().sales_order_id;
            this.sales_order_cancelled = doc.data().sales_order_cancelled;
            this.sales_order_updated = doc.data().sales_order_updated;
            this.updated_line_items = doc.data().updated_line_items;
            this.customer_name = doc.data().customer_name;
            this.work_order_date_created = doc.data().work_order_date_created;
            this.work_order_deadline = doc.data().work_order_deadline;
            this.work_order_progress = doc.data().work_order_progress;
            this.work_order_production_status =
              doc.data().work_order_production_status;
            this.work_order_special_instructions =
              doc.data().work_order_special_instructions;
            this.line_items = doc.data().line_items;
            this.work_order_created_by_name =
              doc.data().work_order_created_by_name;
            this.attachments = doc.data().attachments;
          });
        });
    },
  },
};
</script>