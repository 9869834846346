<template>
    <v-menu
      bottom
      close-on-content-click
      :offset-y="offset"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
            
        <v-btn
            elevation="2"
            color="#33cc99"
            dark
            class="ml-10"
            v-bind="attrs"
            v-on="on"
        >
            New Work Order
            <v-icon right>mdi-chevron-down</v-icon>
            <!--Notification counter for work orders-->
            <div class="circle">
              <p class="circle-content">{{getNotificationCount()}}</p>
            </div>
        </v-btn>
        </template>
        <v-list>
          
        <!--Make for Sales Order-->
        
          <!--Sales orders modal-->
          <v-dialog
            v-model="dialog"
            scrollable
            max-width="500"
          >
          <template v-slot:activator="{ on, attrs }">
        <v-list-item
          link
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-tag-plus</v-icon>
          <v-list-item-content>From Sales Order</v-list-item-content>
        </v-list-item>
          </template>
          <v-card>
            <v-card-title>
                    <h3 class="ml-5">Select a Sales Order</h3>
            </v-card-title>
            <v-card-text style="height: 480px;">
            <v-container>
              <v-row class="px-3">
                  <v-col>
                    <v-text-field
                      type="text"
                      v-model="searchQuery"
                      placeholder="Search for a sales order..."
                      append-icon="mdi-magnify"
                      outlined
                      solo
                    />
                  </v-col>
                </v-row>
              <v-list v-for="item in filteredSalesOrders"
                  :key="item.order_number">
                <v-list-item
                  
                  link
                  :to="{ name: 'new-work-order-sale', params: { order_id: item.order_id }}"
                  v-if="filteredSalesOrders"
                >
                
                    <v-list-item-content>
                      <v-list-item-title>{{item.order_number}}</v-list-item-title>
                      <v-list-item-subtitle><span class="mr-2">{{item.customer.customer_name}}</span> | <span class="ml-2">{{item.order_date}}</span></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="#33cc99">mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    
                </v-list-item>
              </v-list>
            </v-container>
            </v-card-text>
        </v-card>
        </v-dialog>
        

        <!--Make for stock-->
        <v-list-item link to="/new-work-order-stock">
          <v-icon left>mdi-warehouse</v-icon>
          <v-list-item-content>For Stock</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'

export default {
  data(){
    return{
      offset: true,
      dialog: false,
      //Reveals or hides notification counter on button
      displayNotifications: true,
      notificationCount: '',

      //List of sales orders from database
      sales_orders: [],
      //Searching for sales orders in modal
      searchQuery:'',
    }
  },
  created(){
    //Retreive confirmed orders that haven't already been assigned a work order
    db.collection('sales_orders').where('order_status', '==', 'Confirmed').where('work_order_production_status', '==', 'Not Started').get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          //Pushes the sales orders to the empty sales_orders array in 'data' above
          this.sales_orders.push(data)
          console.log(this.sales_orders.length)

        })
      })
  },
  computed: {
    //Search query to filter sales order selection before creating new work order
    filteredSalesOrders (){
      if(this.searchQuery){
      return this.sales_orders.filter((item)=>{
        return item.order_number.includes(this.searchQuery.toUpperCase());
      })
      }else{
        return this.sales_orders;
      }
    }    
  },
  methods:{
    getNotificationCount(){
      return this.notificationCount = this.sales_orders.length
    }
  }
  
}
</script>

<style scoped>
  /*Styles for notification icon on the New Work Order Button*/
  .circle {
  background: #ce2458;
  border-radius: 50%;
  color: #ffffff;
  height: 1.3em;
  position: absolute;
  top: -17px;
  left: 168px;
  width: 1.3em;
}

.circle-content {
  hyphens: auto;
  margin-top: 2px;
  text-align: center;
  font-size: 0.8em;
}

</style>