<template>
    <div>
        <WorkOrdersTable />
    </div>
</template>

<script>
import WorkOrdersTable from '../components/WorkOrdersTable'

export default {
    name: 'WorkOrders',
    components: {
        WorkOrdersTable
    }
}
</script>