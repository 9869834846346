<template>
  <v-container fluid>
    <v-row
      dense
      v-if="this.user_roles_list.flat().includes('work_orders_write')"
    >
      <v-col lg="8" offset-lg="2">
        <v-card-title>
          <v-btn to="/work-orders" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Create New Work Order</h3>
          <!--Show secondary action button-->
          <v-spacer></v-spacer>
          <v-btn
            elevation="2"
            color="#e7e7f7"
            light
            class="ml-10"
            @click="generateWorkOrder"
          >
            <v-icon left>mdi-printer</v-icon>
            Print Work Order
          </v-btn>
        </v-card-title>
        <v-card>
          <!--New Work order form -->
          <v-form
            @submit.prevent="saveWorkOrder"
            class="pa-3"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <!--Progress Bar-->
              <v-row>
                <v-col cols="12">
                  <v-progress-linear
                    v-model="progressBar"
                    height="15"
                    rounded
                    color="#f9c82b"
                  >
                  </v-progress-linear>
                </v-col>
              </v-row>
              <!--End of progress bar-->
              <v-row>
                <v-col cols="12">
                  <h4>Work Order Details</h4>
                </v-col>
              </v-row>
              <v-row>
                <!--Work Order number-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    :value="work_order_number"
                    label="Work Order #"
                    readonly
                    single-line
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
                <!--Sales Order # is filled in automatically-->
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="sales_order_number"
                    readonly
                    cache-items
                    label="Sales Order"
                    outlined
                    dense
                    color="#3d2cdd"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <!--Deadline Date Picker-->
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="true"
                    :return-value.sync="date"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="work_order_deadline"
                        label="Deadline"
                        append-icon="mdi-calendar"
                        :rules="[RequireFormInput('date')]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                        color="#3d2cdd"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="work_order_deadline"
                      no-title
                      scrollable
                      color="#3d2cdd"
                      show-adjacent-months
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="#ce2458" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="#33cc99"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <!--Work Order status-->
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-model="work_order_production_status"
                    required
                    :items="work_order_status_items"
                    label="Production Status"
                    color="#3d2cdd"
                    outlined
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>

              <!--Line Items-->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Line Items</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-form v-for="item in line_items" :key="item.product_name">
                    <v-container>
                      <v-row>
                        <!--Product Name-->
                        <v-col sm="4" md="4">
                          <v-text-field
                            v-model="item.product_name"
                            label="Item"
                            color="#3d2cdd"
                            readonly
                          />
                        </v-col>
                        <!--Quantity Required-->
                        <v-col sm="2" md="2">
                          <v-text-field
                            v-model.number="item.item_qty"
                            label="Qty Required"
                            color="#3d2cdd"
                            type="number"
                            min="0"
                            step="1"
                            outlined
                            dense
                          />
                        </v-col>
                        <!--Quantity completed-->
                        <v-col sm="2" md="2">
                          <v-text-field
                            v-model.number="item.qty_completed"
                            label="Qty Completed"
                            color="#3d2cdd"
                            type="number"
                            min="0"
                            step="1"
                            outlined
                            dense
                          />
                        </v-col>
                        <!--Employee Assigned-->
                        <v-col sm="4" md="4">
                          <v-autocomplete
                            v-model="item.employee_assigned"
                            :items="employees"
                            item-text="employee_fullName"
                            color="#3d2cdd"
                            outlined
                            dense
                            label="Employee Assigned"
                          >
                            <template v-slot:selection="data" trim>
                              {{ data.item.employee_fullName }}
                            </template>
                            <!--Dropdown items-->
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{
                                    data.item.employee_fullName
                                  }}</v-list-item-title
                                >
                                <v-list-item-subtitle>
                                  {{
                                    data.item.employee_jobDescription
                                  }}</v-list-item-subtitle
                                >
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-col>
              </v-row>
              <!--Special instructions-->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Special Instructions (Notes)</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="work_order_special_instructions"
                    clearable
                    counter
                    color="#3d2cdd"
                    outlined
                    dense
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn to="/work-orders" color="#ce2458" text x-large>
              Cancel
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn
              @click="saveWorkOrder"
              color="#33cc99"
              text
              x-large
              :disabled="!valid"
              :loading="loading"
            >
              Submit
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <NoPermissionsCard />
    </div>
  </v-container>
</template>

<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
//Mixins
import mixin_UserRoles from "../../../globalActions/mixin_UserRoles";
import mixin_InitWorkOrder from "../mixins/mixin_InitWorkOrder";
import mixin_PDFworkOrder from "../mixins/mixin_PDFworkOrder";
import mixin_CompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import mixin_progressBar from "../mixins/mixin_progressBar";
import { showSnackbar } from "../../../globalActions/index";
//Components
import NoPermissionsCard from "../../../components/Cards/NoPermissionsCard";

export default {
  name: "NewWorkOrder_FromSale",
  mixins: [
    mixin_UserRoles,
    mixin_InitWorkOrder,
    mixin_PDFworkOrder,
    mixin_CompanyProfile,
    mixin_progressBar,
  ],
  components: { NoPermissionsCard },

  data() {
    return {
      //Sales orders populated from database
      sales_order_number: null,
      sales_order_id: null,
      sales_order_updated: false,
      sales_order_cancelled: false,
      //Product values
      work_order_type: "From Sale",
      loading: false,
      work_order_created_by_id: null,
      work_order_created_by_name: null,
      attachments: null,
    };
  },
  //Database query that runs before the page loads
  beforeRouteEnter(to, from, next) {
    db.collection("sales_orders")
      .where("order_id", "==", to.params.order_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //vm assigns all the values we want to put in the template
          next((vm) => {
            //Sets template values to those stored in the database
            vm.sales_order_id = doc.data().order_id;
            vm.customer_name = doc.data().customer.customer_name;
            vm.customer_id = doc.data().customer.customer_id;
            vm.sales_order_number = doc.data().order_number;
            vm.line_items = doc.data().line_items;
            vm.attachments = doc.data().attachments;
          });
        });
      });
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    //Get Work Order Number
    db
      .collection("work_order_number_counter")
      .doc("ruQc3stD9jaDZpBEJ5wG")
      .get()
      .then((snapshot) => {
        // Gets corder and quote counter object from datatbase
        const count = snapshot.data().work_order_count;
        this.work_order_number = "WO-".concat(count.toString());
      }),
      //Get employees from database
      db
        .collection("employees")
        .where("employee_status", "==", "Employed")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            //Pushes the customers name to the empty customers array in 'data' above
            this.employees.push(data);
          });
        }),
      //Captures user that created the sales order
      (this.work_order_created_by_id = firebase.auth().currentUser.uid);
    db.collection("users")
      .where("uid", "==", this.work_order_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.work_order_created_by_name = doc.data().displayName;
        });
      });
  },
  methods: {
    saveWorkOrder() {
      //Form validation --> ensures required fields aren't blank before continuing further
      if (this.$refs.form.validate()) {
        this.loading = true;
        var newDocRef = db.collection("work_orders").doc();
        newDocRef
          .set({
            //Populated by the v-model values in the form
            //NB: Sets the order_id == to the document id in database
            work_order_id: newDocRef.id,
            work_order_number: this.work_order_number,
            work_order_type: this.work_order_type,
            sales_order_number: this.sales_order_number,
            sales_order_id: this.sales_order_id,
            sales_order_updated: this.sales_order_updated,
            sales_order_cancelled: this.sales_order_cancelled,
            customer_name: this.customer_name,
            customer_id: this.customer_id,
            work_order_deadline: this.work_order_deadline,
            work_order_production_status: this.work_order_production_status,
            work_order_progress: 0,
            work_order_special_instructions:
              this.work_order_special_instructions,
            line_items: this.line_items,
            work_order_date_created: new Date().toISOString().slice(0, 10),
            work_order_timestamp:
              firebase.firestore.FieldValue.serverTimestamp(),
            work_order_created_by_id: this.work_order_created_by_id,
            work_order_created_by_name: this.work_order_created_by_name,
            attachments: this.attachments ? this.attachments : null,
          })
          .then(() => {
            const db = firebase.firestore();
            //Work Order number counter
            //A counter in a doc in firebase is incremented when a new work order is generated
            //The latest count is then used as the next work order number
            const increment = firebase.firestore.FieldValue.increment(1);
            const workOrderNumberCount = db
              .collection("work_order_number_counter")
              .doc("ruQc3stD9jaDZpBEJ5wG");
            //Increments work order counter
            workOrderNumberCount.update({ work_order_count: increment });

            //Add work order details to sales order
            db.collection("sales_orders")
              .where("order_id", "==", this.sales_order_id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref.update({
                    work_order_id: newDocRef.id,
                    work_order_production_status:
                      this.work_order_production_status,
                    work_order_number: this.work_order_number,
                    work_order_date_created: this.work_order_date_created,
                    work_order_deadline: this.work_order_deadline,
                    work_order_created_by_id: this.work_order_created_by_id,
                    work_order_created_by_name: this.work_order_created_by_name,
                  });
                });
              }) //Reroute to sales order view page after database has been updated
              .then(() => {
                this.loading = false;
                showSnackbar("New Work Order created successfully!");
                //Reroute to work orders list after database is updated
                this.$router.push("/work-orders");
              });
          });
        //Update the work order status on the sales order
        //Send Work order number and ID to sales order
      }
    },
  },
};
</script>
