//Initialise form values on New & Edit Sales Order views
const mixin_InitWorkOrder = {
    data(){
        return{
            updated_line_items: false,
            work_order_number: null,
            work_order_date_created: null,
            work_order_deadline: null,
            work_order_timestamp: null,
            work_order_date_completed: null,
            work_order_production_status: 'Work Order Created',
            work_order_progress: null,
            work_order_special_instructions: null,
            sales_order_id: null,
            sales_order_number: null,
            sales_order_updated: null,
            sales_order_cancelled: null,
            search: '',
            line_items: [
                {
                product_name: '',
                item_qty: 0,
                qty_completed: 0,
                employee_assigned: '',
                }
            ],
            //Date picker values
            menu: null,
            date: null,
            //Items used to populate Work Order Status dropdown
            work_order_status_items: [
                {text: 'On Hold', value: 'On Hold', disabled: false},
                {text: 'Cancelled', value: 'Cancelled', disabled: false},
            ],
            //Employees retreived from database
            employees: [],
            //Notify user that sales order is cancelled or deleted
            deleteSnackbar: false,
            //Form validation rules
            valid: false,
            RequireFormInput(propertyType) {
                return v => v && v.length !== null || `You must specify a ${propertyType}`
            },
            minLength(propertyType, minLength){
                return v => v && v.length >= minLength || `The ${propertyType} must be at least ${minLength} characters long`
            },
            maxLength(propertyType, maxLength){
                return v => v.length <= maxLength || `The ${propertyType} can't be more than ${maxLength} characters long`
            }
        }
    },
}

export default mixin_InitWorkOrder
