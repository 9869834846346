const mixin_progressBar = {
    computed: {
        //Progress bar formula
        progressBar(){
            //1) Get qty required as value
            const qr = this.line_items.reduce((total, item) => {
            return total + Number(item.item_qty);
            }, 0)  
            //2) Get quantity completed as value
            const qc = this.line_items.reduce((total, item) => {
            return total + Number(item.qty_completed);
            }, 0)
            //Return quantities of ratio x 100
            return  this.work_order_progress = (qc / qr) * 100
        }
    }
}

export default mixin_progressBar