<template>
    <div>
         <v-container>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Work Orders</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <NewWorkOrderButton
                          v-if="!firstLoad"
                        />
                        <v-spacer/>
                        <ButtonCTA
                            :button_name="viewWorkOrderScheduleBtn.btn_work_order_schedule_name"
                            :button_to="viewWorkOrderScheduleBtn.btn_work_order_schedule_to"
                            :button_color="secondaryCTA.btn_secondary_color"
                            :button_icon="secondaryCTA.btn_view_icon"
                            :button_margin_left="secondaryCTA.btn_ml_10"
                            v-if="!firstLoad"
                            v-show="this.user_roles_list.flat().includes('work_orders_read')"
                        >
                        </ButtonCTA>
                    </v-card-title>
                </v-col>
            </v-row>

            <!--Card skeleton loader-->
            <v-row class="mb-3" v-if="firstLoad">
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
              <v-col md="3" lg="3">
                <v-skeleton-loader :loading="loading_user" type="card" max-height="88">
                    </v-skeleton-loader>
              </v-col>
            </v-row>
            <!--Dashboard summary tiles-->
            <v-row class="mb-3" v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('work_orders_read')">
              <v-col md="3" lg="3" v-for="item in dashboard_items" :key="item.name">
                <v-sheet elevation="2">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <v-icon
                          left
                          x-small
                          :color="item.color"
                        >
                          mdi-circle</v-icon>
                        {{item.name}}
                      </v-list-item-subtitle >
                      <v-list-item-title class="headline mb-1 font-weight-bold">
                        {{countDashboardItems(item.item_work_order_status)}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-sheet>
              </v-col>
              <!--Updated Sales Orders-->
              <v-col md="3" lg="3">
                <v-sheet elevation="2">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-bold">
                        <v-icon
                          left
                          x-small
                          color="#f9d493"
                        >
                          mdi-circle</v-icon>
                        Updated Sales Orders
                      </v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1 font-weight-bold">
                        {{updatedSalesCount()}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-sheet>
              </v-col>
              <!--Cancelled Sales Orders-->
              <v-col md="3" lg="3">
                <v-sheet elevation="2">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-bold">
                        <v-icon
                          left
                          x-small
                          color="#ce2458"
                        >
                          mdi-circle</v-icon>
                        Cancelled Sales Orders
                      </v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1 font-weight-bold">
                        {{cancelledSalesCount()}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-sheet>
              </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="290">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('work_orders_read')">
              <v-col>
                    <!--Work Order data table-->    
                    <v-sheet elevation="4">
                    <template>
                      <div>
                        <v-data-table
                          :headers="workOrdersTableHeaders"
                          :items="filteredItems"
                          :search="search"
                          :item-class= "row_classes"   
                        >
                      <!--Filter Work Orders by production status-->  
                      <template v-slot:top>
                        <v-container fluid>
                            <v-row>
                              <v-col lg="3" md="3">
                                <v-select
                                  :items="['Work Order Created','In Progress','Completed','On Hold', 'Cancelled']"
                                  v-model='workOrderStatus'
                                  label="Production Status"
                                  outlined
                                  flat
                                  solo
                                  dense
                                  append-icon="mdi-filter-variant"
                                >
                                </v-select> 
                              </v-col> 
                              <v-spacer />
                              <!--Search bar filter -->
                              <v-col lg="4" md="4">
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                rounded
                                dense
                                filled
                                clearable
                                ></v-text-field>
                              </v-col>
                            </v-row>
                        </v-container> 
                      </template>

                      <!--Edit icon button-->
                      <template
                        v-slot:item.work_order_id={item}
                        v-if="this.user_roles_list.flat().includes('work_orders_write')"
                      >
                          <v-btn
                              :to="{ name: 'edit-work-order', params: { work_order_id: item.work_order_id }}"
                              color="#3d2cdd"
                              icon
                              small
                          ><v-icon small>mdi-pencil</v-icon></v-btn>
                      </template>
                      <!--Disable edit button based on user roles-->
                      <template
                        v-slot:item.work_order_id
                        v-else
                      >
                        <v-btn
                            color="#3d2cdd"
                            icon
                            small
                            disabled
                        ><v-icon small>mdi-pencil-off</v-icon></v-btn>
                      </template>
                      <!--Preview Work Order link-->
                      <template v-slot:item.work_order_number={item}>
                         <router-link
                            class="font-weight-medium"
                            style="text-decoration: none"
                            :to="{ name: 'view-work-order', params: { work_order_id: item.work_order_id }}">
                            {{item.work_order_number}}
                          </router-link>
                      </template>
                       <!--Work order type chip
                            From Sale-->
                      <template v-slot:item.work_order_type="props">
                          <v-chip
                              color="#3d2cdd"
                              dark
                              outlined
                              label
                            >
                            <v-icon left small>{{saleOrStockIcon(props.item.work_order_type)}}</v-icon>
                              {{props.item.work_order_type}}
                              <span
                                class="ml-1"
                              >
                                {{props.item.sales_order_number}}
                                </span>
                          </v-chip>
                      </template>
                      <!--Production status label colour changes dynamically-->
                        <template v-slot:item.work_order_production_status="props">
                          <v-chip
                            class="status-chip"
                            label
                            :color="getColorWorkOrders(props.item.work_order_production_status)"
                            :dark="getDarkOrLight(props.item.work_order_production_status)"
                            >
                            <v-avatar
                              left
                            >
                              <v-icon small>{{getWorkOrderIcon(props.item.work_order_production_status)}}</v-icon>
                            </v-avatar>
                            
                              {{ props.item.work_order_production_status}}
                              
                          </v-chip>
                      </template>
                      <!--Progress Bar-->
                        <template v-slot:item.work_order_progress={item}>
                              <v-progress-linear
                                  height="15"
                                  rounded
                                  :color="progressBarColor"
                                  :value="item.work_order_progress"
                                >
                                </v-progress-linear>
                        </template>
                        </v-data-table>
                      </div>
                    </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import store from '../store/store'
import {mapState} from 'vuex'
import ButtonCTA from '../../../components/Buttons/ButtonCTA'
import NewWorkOrderButton from '../components/NewWorkOrderButton'

export default {
    name: 'WorkOrdersTable',
    mixins: [mixin_UserRoles],
    components: {NewWorkOrderButton, ButtonCTA},
    data(){
        return {
            work_orders: [],
            search: '',
            workOrderStatus: 'Work Order Created',
            dashboard_items: [
            {name: 'Work Order Created', item_work_order_status: 'Work Order Created', color: '#c5d0ed'},
            {name: 'In Progress', item_work_order_status: 'In Progress', color: '#88e2b7'}
          ]
        }
    },
    store,
    computed: mapState({
        //This is where data is retrieved from the Vuex state
        workOrdersTableHeaders: state => state.workOrdersTableHeaders,

        //Work order dashboard filter
        filteredItems() {
        return this.work_orders.filter((i) => 
          {
            return this.workOrderStatus === '' || (i.work_order_production_status === this.workOrderStatus)
          })
        },
        viewWorkOrderScheduleBtn: state => state.viewWorkOrderScheduleBtn,
        secondaryCTA: state => state.secondaryCTA,
        progressBarColor(){
            return this.work_order_progress < 100 ? '#f9c82b' : "#33cc99"
        },        
    }),
    //Get work orders from database
    created(){     
        db.collection('work_orders').orderBy('work_order_number').get()
        .then(querySnapshot => {
          this.work_orders = []
          querySnapshot.forEach(doc => {
            const data = {
              'id': doc.id,
              'work_order_id': doc.data().work_order_id,
              'work_order_number': doc.data().work_order_number,
              'work_order_type': doc.data().work_order_type,
              'work_order_date_created': doc.data().work_order_date_created,
              'work_order_production_status': doc.data().work_order_production_status,
              'work_order_deadline': doc.data().work_order_deadline,
              'work_order_progress': doc.data().work_order_progress,
              'sales_order_number': doc.data().sales_order_number,
              'sales_order_updated': doc.data().sales_order_updated,
              'sales_order_cancelled': doc.data().sales_order_cancelled
            }
            //Pushes the customers objects to the empty customers array above
            this.work_orders.push(data)
          })
        })
      },
      methods: {
      stockOrSale(){
        if(this.work_order_type==="From Sale"){
          return true
        }else if(this.work_order_type==='For Stock'){
          return false
        }
      },
      //Determine work order status icon
        saleOrStockIcon(work_order_type) {
            if(work_order_type==="From Sale"){
              return 'mdi-tag-plus'
          }else if(work_order_type==='For Stock'){
              return 'mdi-warehouse'
          }
        },
        //Conditional formatting for work order status
      getColorWorkOrders (work_order_production_status) {
        if (work_order_production_status == "Work Order Created") return '#c5d0ed'
        else if (work_order_production_status == "In Progress") return '#f9d493'
        else if (work_order_production_status == "Completed") return '#88e2b7'
        else if (work_order_production_status == "On Hold") return '#ef9ebd'
        else if (work_order_production_status == "Cancelled") return '#ce2458'
        else return 'white'
      },
      getWorkOrderIcon(work_order_production_status) {
        if (work_order_production_status == "Work Order Created") return 'mdi-tag-plus'
        else if (work_order_production_status == "In Progress") return 'mdi-camera-timer'
        else if (work_order_production_status == "Completed") return 'mdi-check'
        else if (work_order_production_status == "On Hold") return 'mdi-pause'
        else if (work_order_production_status == "Cancelled") return 'mdi-cancel'
        else return 'white'
      },
      getDarkOrLight(work_order_production_status){
        if (work_order_production_status != "Cancelled") return false
        else return true
      },
      //Changes row background color depending on sales order updates or cancellations
      //Cancelled status has higher priority than updated status
      row_classes(item) {
        if (item.sales_order_cancelled === true){
          return "pink lighten-4"
        }
        else if (item.sales_order_updated === true) {
          return "orange lighten-4"
        } 
        else return "white"
      },
      //  Calculates total count of work orders for each production status
      //  and displays them on dashbaord summary pannel 
      countDashboardItems(item_work_order_status){
        const statusCount = this.work_orders
      .filter(work_orders => work_orders.work_order_production_status === item_work_order_status)
       return statusCount.length
      },
      updatedSalesCount(){
        const statusCount = this.work_orders
      .filter(work_orders => work_orders.sales_order_updated)
       return statusCount.length
      },
      cancelledSalesCount(){
        const cancelCount = this.work_orders
      .filter(work_orders => work_orders.sales_order_cancelled)
       return cancelCount.length
      }
  }
}
</script>